<template>
  <div class="dataM bomManagement">
    <div class="content">
      <div class="title">
        <div>BOM单列表</div>
        <div class="tool">
          <upload @uploadFile="uploadFile" ref="upload"/>
          <el-input
            v-model="searchValue"
            style="width:220px"
            size="small"
            placeholder="请输入物料编号/名称/图号"
            >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch(1)"></i>
          </el-input>
        </div>
      </div>
      <el-table :data="tableData" header-cell-class-name="tableHeader" style="width: 100%" @expand-change="getBomTree">
        <el-table-column type="expand">
          <template>
            <el-tree default-expand-all :data="treeData" :props="defaultProps"></el-tree>
          </template>
        </el-table-column>
        <el-table-column prop="resourceCode" label="物料编号"/>
        <el-table-column prop="resourceName" label="产品名称"/>
        <el-table-column prop="partCode" label="图号"/>
        <el-table-column prop="model" label="规格型号"/>
        <el-table-column prop="versionCode" label="版本"/>
        <el-table-column prop="modifyTime" label="更新日期"/>
        <!-- <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="operation">
              <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
      <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="handleSearch"/>
    </div>
    <resultDialog 
      name="BOM单" 
      :resultVisible.sync="resultVisible" 
      :type="resultType" 
      :fileName="fileName"
      :resultMsg="resultMsg"
      @reUpload="reUpload"
    />
  </div>
</template>

<script>
const pagination = () => import('@/components/pagination')
const upload = () => import('@/components/upload/upload')
const resultDialog = () => import('@/components/upload/resultDialog')
import { getBOMList,delBOMList,importBOM,getBomDetails } from '@/api/data.js'
import { isComfirm } from '@/utils/data.js'
export default {
  name: "bomManagement",
  components:{
    pagination,
    upload,
    resultDialog
  },
  data() {
    return {
      tableData:[],
      total:0,
      page:1,
      size:10,
      searchValue:'',
      resultVisible:false,
      resultType:true,
      resultMsg:'',
      fileName:'',
      defaultProps: {
        children: 'childrenBom',
        label: 'resourceName'
      },
      treeData:[],
    };
  },
  watch:{
    size(){
      this.handleSearch(1)
    }
  },
  activated(){
    this.handleSearch()
  },
  methods:{
    handleSearch(page){
      page ? this.page = page : this.page = 1;
      let params = {
        page:this.page,
        size:this.size,
        searchValue:this.searchValue
      }
      getBOMList(params).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.msg || '接口出错')
        }
      })
    },
    comfirm(row){
      isComfirm(row,this.del)
    },
    del(row){
      delBOMList(row.id).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    //获取BOM树形结构
    getBomTree(row){
      getBomDetails(row.id).then(res=>{
        console.log('res',res)
        if(res.code === 0){
          this.$set(this.treeData,0,res.data)
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    uploadFile(file){
      let formData = new FormData();
      formData.append('file',file.raw);
      this.fileName = file.name
      importBOM(formData).then(res=>{
        this.resultMsg = res.msg
        if(res.code == 0){
          this.resultVisible = true
          this.resultType = true
          this.handleSearch(1)
        }else{
          this.resultVisible = true
          this.resultType = false
        }
      }).catch((error)=>{
        console.error('error',error.response)
        this.resultMsg = error.response?.data?.msg
        this.resultVisible = true
        this.resultType = false
      })
    },
    reUpload(){
      this.$refs.upload.reUpload()
    }
  }
};
</script>

<style lang="scss">
.bomManagement{
  padding: 18px;
  color: #E8E8E8;
  .content{
    position: relative;
    background: #232323;
    min-height: calc(100vh - 280px);
    text-align: left;
    padding: 25px 35px 80px 35px;
    .tableHeader{
      background: #2C2C2C;
      color: #B9B9B9;
    }
    .title{
      font-size: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    .tool{
      width: 300px;
      display: flex;
      align-items: center;
      img{
        margin: 0 15px;
        cursor: pointer;
        width: 22px;
        height: 22px;
      }
    }
    .operation{
      font-size: 16px;
      display: flex;
      align-items: center;
      span{
        display: inline-block;
        margin-left: 10px;
      }
    }
    img{
      width: 22px;
      height: 22px;
    }
  }
  td.el-table__cell.el-table__expanded-cell{
    padding: 10px 60px;
    .el-tree{
      background: #2C2C2C;
      padding: 20px 65px;
      .el-tree-node__content{
        border-bottom: 1px solid #3E3E3E;
      }
    }
  }
}
</style>

